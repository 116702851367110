import React from 'react';
import { Link } from 'react-router-dom';

import home_icon from '../../assets/frontend/images/home_icon.png';
import check_list_icon from '../../assets/frontend/images/check_list_icon.png';
import portfolio_icon from '../../assets/frontend/images/portfolio_icon.png';
import location_finder_icon from '../../assets/frontend/images/location_finder_icon.png';
import weather_icon from '../../assets/frontend/images/weather_icon.png';
import videos_icon from '../../assets/frontend/images/videos_icon.png';
import payments_icon from '../../assets/frontend/images/payments_icon.png';
import account_icon from '../../assets/frontend/images/account_icon.png';

function Dashboard() {

	return (
		<div className="body-content bg-fff pt-70l-110m-50s pb-40">

			<div className="panel large pt-20l-10s">
				<div className="grid-x">
					<div className="large-12 medium-12 small-12 cell text-left">
						<div className="font-source-sans font-size-20 font-weight-800 txt-dark-blue bb1-dark-blue uppercase pb-5">Dashboard</div>
					</div>
				</div>
			</div>

			<div className="panel large">
				<div className="grid-x">
					<div className="large-2 medium-2 small-4 cell pt-40 text-center">
						<Link to="/member/check_list" className="button icon-dashboard image-container no-underline">
							<img src={check_list_icon} alt="check_list_icon" width="38" className="pt-10 pb-5" />
							<div className="font-source-sans font-size-11 font-weight-600 letter-spacing-0px uppercase">Lists</div>
						</Link>
					</div>
					<div className="large-2 medium-2 small-4 cell pt-40 text-center">
						<Link to="/member/portfolio" className="button icon-dashboard image-container no-underline">
							<img src={portfolio_icon} alt="portfolio_icon" width="38" className="pt-10 pb-5" />
							<div className="font-source-sans font-size-11 font-weight-600 letter-spacing-0px uppercase">Portfolios</div>
						</Link>
					</div>
					<div className="large-2 medium-2 small-4 cell pt-40 text-center">
						<Link to="/member/location_finder" className="button icon-dashboard image-container no-underline">
							<img src={location_finder_icon} alt="location_finder_icon" width="38" className="pt-10 pb-5" />
							<div className="font-source-sans font-size-11 font-weight-600 letter-spacing-0px uppercase">Locations</div>
						</Link>
					</div>
					<div className="large-2 medium-2 small-4 cell pt-40 text-center">
						<Link to="/member/weather" className="button icon-dashboard image-container no-underline">
							<img src={weather_icon} alt="weather_icon" width="38" className="pt-10 pb-5" />
							<div className="font-source-sans font-size-11 font-weight-600 letter-spacing-0px uppercase">Weather</div>
						</Link>
					</div>
					<div className="large-2 medium-2 small-4 cell pt-40 text-center">
						<Link to="/member/videos" className="button icon-dashboard image-container no-underline">
							<img src={videos_icon} alt="videos_icon" width="38" className="pt-10 pb-5" />
							<div className="font-source-sans font-size-11 font-weight-600 letter-spacing-0px uppercase">Videos</div>
						</Link>
					</div>
					<div className="large-2 medium-2 small-4 cell pt-40 text-center">
						<Link to="/member/payments" className="button icon-dashboard image-container no-underline">
							<img src={payments_icon} alt="payments_icon" width="38" className="pt-10 pb-5" />
							<div className="font-source-sans font-size-11 font-weight-600 letter-spacing-0px uppercase">Payments</div>
						</Link>
					</div>
				</div>
			</div>

		</div>

	);
}

export default Dashboard;